<roots-page-header [toolTip]="toolTip" [title]="title" [loading]="loading">
</roots-page-header>
<div class="col-12">
  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xxl-6" style="float: left;" *ngIf="scout">
    <div *ngIf="coordinates">
      <mat-accordion class="col-12">
        <mat-expansion-panel>
          <mat-expansion-panel-header style="padding-left: 15px; padding-right: 15px;">
            <mat-panel-title style="font-size: 25px; font-weight: 500;"> Map </mat-panel-title>
            <mat-panel-description style="font-size: 1.2rem; justify-content: space-between; align-items: center;">
              Scout Location
              <mat-icon>map</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-asset-map [installs]="installs" [coordinates]="coordinates"></app-asset-map>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <mat-card>
      <mat-card-actions *ngIf="scoutId">
        <app-scout-interval [scoutId]="scoutId" [counterValue]="interval" [scout]="scout">
        </app-scout-interval>
      </mat-card-actions>
    </mat-card>
    <mat-card>
      <h3 class="text label-header-text">Details</h3>
      <hr />
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" [(ngModel)]="scout.basicName" disabled required />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput placeholder="Description" [(ngModel)]="scout.basicDescription" disabled required />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Statistics</mat-label>
        <input matInput placeholder="Statistics" [(ngModel)]="scout.statistics" disabled required />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Interval Sync Status</mat-label>
        <input matInput placeholder="Interval sync status" [(ngModel)]="scout.intervalSyncStatus" disabled />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Mode</mat-label>
        <input matInput placeholder="Mode" [(ngModel)]="scout.mode" disabled required />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Created At</mat-label>
        <input matInput placeholder="Created At" [(ngModel)]="scout.createdAt" disabled required />
      </mat-form-field>

      <button mat-raised-button type="submit" style="max-width: fit-content" color="primary" disabled>
        Update
      </button>
    </mat-card>
    <mat-accordion *ngIf="scoutdata"  class="col-12">
      <mat-expansion-panel>
        <mat-expansion-panel-header style="padding-left: 15px; padding-right: 15px;">
          <mat-panel-title style="font-size: 25px; font-weight: 500;"> Technical Details </mat-panel-title>
          <mat-panel-description style="font-size: 1.2rem; justify-content: space-between; align-items: center;">
            Asset Model and Info
            <mat-icon>settings</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Asset Model -->
        <mat-card *ngIf="scoutdata != null && scoutdata.assetModelInfo">
          <div style="overflow: auto; overflow-wrap: break-word">
            <h3 class="text label-header-text">
              <mat-icon>devices</mat-icon>
              Asset Model:
            </h3>
            <hr>
            <p class="text dashboard-text" style="margin: 0px;">
              Asset Kind Codes: {{scoutdata.assetModelInfo.assetKindCodes}}
            </p>
            <ul style="text-align: center; list-style: none;">
              <li *ngFor="let capability of scoutdata.assetModelInfo.capabilities">
                <p class="text dashboard-text" style="margin: 0px;">
                  <b>{{ capability.code }}: {{ capability.value }}</b>
                </p>
              </li>
            </ul>
          </div>
        </mat-card>
        <!-- INFO -->
        <mat-card *ngIf="scoutdata != null && scoutdata.healthSensorId">
          <h3 class="text label-header-text">
            <mat-icon>info</mat-icon>
            Info:
          </h3>
          <hr />
          <ul style="overflow: auto; overflow-wrap: break-word">
            <li class="text dashboard-text">
              Health Sensor: {{scoutdata.healthSensorId}}
              <a mat-button (click)="navigateToHealth(scoutdata.healthSensorId)" style="float: right;">
                <mat-icon>search</mat-icon>
              </a>
            </li>
            <li class="text dashboard-text">
              Holding: {{scoutdata.holdingId}}
              <!-- <a mat-button (click)="navigateToHolding(scoutdata.holdingId)" style="float: right;">
              <mat-icon>search</mat-icon>
            </a> -->
            </li>
            <li class="text dashboard-text">
              Notification Count: {{scoutdata.notificationConfirmationCount}}
            </li>
          </ul>
        </mat-card>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <app-notes *ngIf="scout" [entityId]="scoutId" [entityCode]="scout.code" [entityType]="'Scout'" [notes]="notes"
    (noteAdded)="noteAdded($event)">
  </app-notes>

  <div *ngIf="scoutdata" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xxl-6" style="float: left">
    <mat-card>
      <div *ngIf="scoutdata; else scoutOffline">
        <h3 class="text label-header-text">
          <mat-icon style="color: green;">mobile_friendly</mat-icon>
          Scout Data
        </h3>
        <hr>
        <p class="text dashboard-text" style="text-align: center; margin: 0px;">
          {{scoutdata.name}}
        </p>
        <p class="text small-text" style="text-align: center; margin: 0px;">
          {{scoutdata.description}}
        </p>
        <p class="text dashboard-text" style="margin: 0px;">
          GPS:
        </p>
        <p class="text dashboard-text" *ngIf="scoutdata.coordinateLatitude; else noGps" style="margin: 0px;">
          [Latitude: {{scoutdata.coordinateLatitude.toFixed(5)}}, Longitude:
          {{scoutdata.coordinateLongitude.toFixed(5)}}, Altitude:
          {{scoutdata.coordinateAltitude}}]
        </p>
        <ng-template #noGps>
          <p class="text small-text" style="margin: 0px; color: red;">
            No GPS data found (Map set to your location)
          </p>
        </ng-template>
      </div>
      <ng-template #scoutOffline>
        <mat-icon style="color: red;">mobile_off</mat-icon>
        No Scout Data
        <p class="text dashboard-text">
          If unit is on and sending, please check to make sure all the correct assets are assigned as well.
        </p>
      </ng-template>
    </mat-card>
    <!-- Firmware -->
    <mat-card *ngIf="scoutdata.firmwareModelInfo">
      <h3 class="text label-header-text">
        <mat-icon>important_devices</mat-icon>
        Firmware:
      </h3>
      <hr>
      <ul style="text-align: center; list-style: none;">
        <li class="text dashboard-text">
          Name: <b>{{scoutdata.firmwareModelInfo.name}}</b>
          <a mat-button (click)="navigateToFirmware(scoutdata.firmwareModelInfo.id)" style="float: right;">
            <mat-icon>search</mat-icon>
          </a>
        </li>
        <li class="text dashboard-text">
          Version: <b>{{scoutdata.firmwareModelInfo.version}}</b>
        </li>
        <li class="text dashboard-text">
          Description: <b>{{scoutdata.firmwareModelInfo.description}}</b>
        </li>
      </ul>
    </mat-card>
  </div>
  <app-roots-details-data-list *ngIf="scoutdata" [data]="scoutdata.sensors" [prop_1]="'name'" [prop_2]="'assetName'"
    [prop_3]="'assetCode'" [prop_4]="'id'" [type]="3" [icon]="'widgets'" [title]="'Sensors'">
  </app-roots-details-data-list>
</div>