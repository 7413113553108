
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from '../../../shared/services/admin.service';
import { AppService } from '../../../shared/services/api/app.service';
import { AssetsService } from '../../../shared/services/assets.service';
import { AdminScoutInstallation } from '../../../shared';
import { Notes } from '../../../shared/interfaces/notes';
@Component({
  selector: 'app-scouts-edit',
  templateUrl: './scouts-edit.component.html'
})
export class ScoutsEditComponent implements OnInit, OnDestroy {
  @Output() selectEmitt = new EventEmitter();
  @Input() scout: any;
  scoutInitState: any;
  event: EventEmitter<any> = new EventEmitter();
  scoutId = '';
  hasAssets = true;
  loading = true;
  scouts: any[] = [];
  scoutdata;
  appScouts: any[] = [];
  appSensors: any[] = [];
  title = "";
  toolTip = "";
  intervalChanged = false;
  installs: AdminScoutInstallation[] = [];
  coordinates: any[] = [];
  notes: Notes[] = [];
  _interval: number;
  componentSubscription: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    public _dialog: MatDialog,
    private _adminService: AdminService,
    private _assetService: AssetsService,
    private _appService: AppService
  ) {
    this.toolTip =
      "Configure or edit scout details.";
  }

  get interval(): number {
    return this._interval;
  }
  set interval(interval: number) {
    if (this._interval !== interval) {
      this._interval = interval;
      this.intervalChanged = true;
    }
  }

  ngOnInit() {
    this._route.paramMap.pipe(
      map(params => {
        const id = params.get('id');
        this.scoutId = id;
        return id;
      }),
      mergeMap(id => {
        const getAppScout = this._appService.getTableScout(id);
        const getAppSensorById = this._appService.getAppSensorById(id);
        const getScoutData = this._adminService.getAppScout();
        const getNotes = this._adminService.getNotesByEntityCodeOrIdSecondary(null, this.scoutId)
          .pipe(catchError((err) => of(undefined)));
        return forkJoin([
          getAppScout,
          getAppSensorById,
          getScoutData,
          getNotes
        ]);
      })).subscribe(results => {
        const appScouts = results[0];
        const appSensors = results[1];
        const scoutData = results[2];
        const notes = results[3];

        this.notes = notes;
        this.scout = appScouts;
        if (this.scout) {
          this.loading = false;
        }
        this.appSensors = appSensors;
        this.title = `${this.scout.basicName} Scout Details`;

        this.scoutdata = scoutData;

        this.scoutdata = this.findScoutData(scoutData);

        if (this.scoutdata != null && this.scoutdata['coordinateLongitude']) {
          this.coordinates = [this.scoutdata['coordinateLatitude'], this.scoutdata['coordinateLongitude']]
          console.log('coordinates: ' + this.coordinates)
        }
        console.log(this.scoutdata);

        this.getInterval()
        this.loading = false;
      })
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  navigateToHealth(id: string) {
    this._router.navigate([`/assets/${id}`])
  }

  navigateToAsset(id: string) {
    this._router.navigate([`/assets/${id}`])
  }

  navigateToFirmware(id: string) {
    this._router.navigate([`/firmwares`])
  }

  findScoutModel(scouts, id: string) {
    let s;
    for (let i = 0; i < scouts.length; i++) {
      const scout = scouts[i];
      if (scout.id === id) {
        s = scout;
      }
    }
    return s;
  }

  findScoutData(scouts: any[]) {
    let s;
    for (let i = 0; i < scouts.length; i++) {
      const scout = scouts[i];
      if (scout.code === this.scout.code) {
        s = scout;
      }
    }
    return s;
  }

  noteAdded($event) {
    if ($event) {
      this._adminService.getNotesByEntityCodeOrIdSecondary(null, this.scoutId).subscribe(result => {
        this.notes = result;
      })
    }
  }


  getInterval() {
    this.loading = true;
    this.componentSubscription = this._assetService.getScoutTable(this.scoutId)
      .subscribe(resp => {
        this.scout = resp;
        this._interval = this._assetService.parseContentField(resp);
        this.loading = false;
      });
  }
}

export interface ScoutTree {
  assetCode: string;
  assetId: string;
  assetModelInfo: {
    assetKindCodes: string;
    capabilities: any[],
    id: string,
    code: string,
    name: string
  }
  assetName: string;
  assetOwnershipId: string;
  assetScoutKind: number;
  baseScoutId: string;
  code: string;
  connectivityBaseScoutCode: string;
  connectivityCode: string;
  content: string;
  coordinateAltitude: number;
  coordinateLatitude: number;
  coordinateLongitude: number;
  description: string;
  firmwareModelInfo: {
    updateInfo: string;
    version: string;
    description: string;
    name: string;
    id: string;
  }
  healthSensorId: string;
  holdingId: string;
  id: string;
  name: string;
  notificationConfirmationCount: number;
  sensors: any[];
  statistic: any[]
}