import { HttpClient } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, Subscription } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { ConstantService, RootsActionMenuItem, RootsPageComponentConfig } from "../../../../shared";
import { VerifyDialogComponent } from "../../../../shared/components/dialogs/verify-dialog/verify-dialog.component";
import { ScoutLogExtended } from "../../../../shared/interfaces/scout-log-extended";
import { SnackService } from "../../../../shared/services/extra/snack.service";
import { ScoutLogService } from "../../../../shared/services/scout-log.service";
import { SearchBarService } from "../../../../shared/services/searchbar.service";
import { ProdLogScoutDialogComponent } from "../../dialogs/prodlog-scout-dialog/prodlog-scout-dialog.component";

@Component({
  selector: "app-scout-log-details",
  templateUrl: "./scout-log-details.component.html"
})
export class ScoutLogDetailsComponent implements OnInit, OnDestroy {
  scoutLogsForSerial: [] = [];
  serial: string;
  request: Request;
  logs: ScoutLogExtended[];
  _config: RootsPageComponentConfig = {
    loading: true,
    title: "",
    toolTip: "",
    dataSource: new MatTableDataSource<any>(),
    displayedColumns: [
      "time",
      "sequenceNumber",
      "productLine",
      "serial",
      "boardVersion",
      "validated",
      "validatedBy",
    ],
    message: "",
  };
  items: RootsActionMenuItem[] = [
    {
      name: "Show Log",
      toolTip: "Expand details of this log",
      icon: "search",
      type: 10,
    },
    {
      name: "Search Scout",
      toolTip: "Search for the Scouts assets and details",
      icon: "search",
      type: 13,
    },
    {
      name: "Create Assets",
      toolTip: "Create assets based on this unit and its sensors",
      icon: "add",
      type: 11,
    },
    {
      name: "Validate/ Un-Validate Log",
      toolTip: "Confirm validation over this log",
      icon: "check",
      type: 12,
    },
  ];
  actionButtons: RootsActionMenuItem[] = [
    {
      name: 'Validate history',
      icon: 'done',
      toolTip: 'Validate entire scout log history',
      type: 0
    },
    {
      name: 'Remove Validation',
      icon: 'doclearne',
      toolTip: 'Remove validation on entire history',
      type: 1
    }
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  searchSubscription: Subscription;
  constructor(
    private _constService: ConstantService,
    private _http: HttpClient,
    private _router: Router,
    private _dialog: MatDialog,
    private _snack: SnackService,
    private _logService: ScoutLogService,
    private _route: ActivatedRoute,
    public searchBarService: SearchBarService
  ) {
    this._config.toolTip = "All scout logs from the manufacturing floor for this given Serial number (data pulled by serial number). Click to open Actions.. Click to refresh.";
  }

  ngOnInit(): void {
    this._route.paramMap
      .pipe(
        map((params) => {
          const id = params.get("serialId");
          this.serial = id;
          return id;
        }),
        mergeMap((id) => {
          const getUserGroupDetails = this.getScoutLogsBySerialId(id);

          return forkJoin([getUserGroupDetails]);
        })
      )
      .subscribe((results) => {
        const details = results[0];

        console.log(details);
        this.logs = details;
        this.logs = this.findCount(this.logs);

        this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
          this._config.message = message;
        });
      }),
      (err) => {
        this._snack.displayError("An error occurred while pulling details");
      };
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  //returns array of logs based on serial
  public getScoutLogsBySerialId(serialId: string) {
    this.request = new Request(
      environment.scoutLogApiUrl + "ScoutLog/serials/" + serialId,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "x-functions-key": environment.SCOUTLOG_API_KEY,
        }),
      }
    );
    fetch(this.request)
      .then((response) => {
        response.json().then((res) => {
          const response = <ScoutLogExtended[]>res;
          this.logs = response;
          this._config.title = `${this.logs[0].sequenceNumber} Scout Logs`;
          this._snack.displaySuccess("Scout Log history loaded");
          this._config.dataSource.data = response;
          this._config.loading = false;
        });
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

  public unValidateHistory(serial: string) {
    // params
    const url =
      environment.scoutLogApiUrl +
      "ScoutLog/serials/" +
      serial +
      "/validatehistory";
    const headers = new Headers();
    let params: URLSearchParams = new URLSearchParams();
    serial = "UNDO_" + serial;
    params.set("serial", serial);
    // params.set('validatedBy', localStorage.getItem('userEmail'));
    headers.set("Content-Type", "application/json");
    headers.set("x-functions-key", this._constService.CONST.pvtKey);

    this.request = new Request(url, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify({
        serial: serial,
        validatedBy: localStorage.getItem("userEmail"),
      }),
    });
    fetch(this.request)
      .then((response) => {
        this._snack.displaySuccess(response.status.toString());
        this.getScoutLogsBySerialId(this.serial);
        //this.logs = details;
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

  public validateHistory(serial: string) {
    // params
    const url =
      environment.scoutLogApiUrl +
      "ScoutLog/serials/" +
      serial +
      "/validatehistory";
    const headers = new Headers();
    let params: URLSearchParams = new URLSearchParams();
    params.set("serial", serial);
    // params.set('validatedBy', localStorage.getItem('userEmail'));
    headers.set("Content-Type", "application/json");
    headers.set("x-functions-key", this._constService.CONST.pvtKey);

    this.request = new Request(url, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify({
        serial: serial,
        validatedBy: localStorage.getItem("userEmail"),
      }),
    });
    fetch(this.request)
      .then((response) => {
        this._snack.displaySuccess(response.status.toString());
        this.getScoutLogsBySerialId(this.serial);
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

  refresh() {
    this.getScoutLogsBySerialId(this.serial);
  }

  createAssetsDialog(log: ScoutLogExtended): void {
    this._logService.createAssetsDialog(log);
  }

  //   boardVersion: "2.2"
  // connectivityCode: null
  // firmwareVersion: null
  // id: "d81e7265-505b-466f-a1d6-d9ba704a9f6e"
  // link: "0000AD7B84F1002F004C100545374E45"
  // modelNumber: null
  // name: "EARTHS-000034"
  // productLine: null
  // sensors: []
  // time: "9/30/2020 6:44:15 PM"
  // type: "Scout"
  // validated: false
  // validatedBy: "conner.soligny@industrialsmart.com"
  // voltage: "3.97 V"
  // [[Prototype]]: Object

  topMenuActionsClicked(action) {
    console.log(action);
    switch (action.type) {
      case 0:
        this.validateHistory(this.serial);
        break;
      case 1:
        this.unValidateHistory(this.serial);
        break;
      default:
        break;
    }
  }

  actionsClicked(action) {
    const type = action[0];
    const item = action[1];
    switch (type.type) {
      case 10:
        this.logDialog(item);
        break;
      case 11:
        this.createAssetsDialog(item);
        break;
      case 12:
        this.createAssetsDialog(item);
        break;
      case 13:
        this._router.navigate([`cubs/${item.serial}`])
        break;
      default:
        break;
    }
  }


  logDialog(log: ScoutLogExtended): void {
    const dialogRef = this._dialog.open(ProdLogScoutDialogComponent, {
      width: "90vw",
      height: "90vh",
      data: log,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        setTimeout(() => {
          this.refresh();
          this._snack.displaySuccess("Reloading... One moment");
        }, 1000);
      }
    });
  }

  logVerifyDialog(log: ScoutLogExtended): void {
    const dialogRef = this._dialog.open(VerifyDialogComponent, {
      width: "60vw",
      // height: '300px',
      data: log,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        setTimeout(() => {
          this.refresh();
          this._snack.displaySuccess("Reloading... One moment");
        }, 1000);
      }
    });
  }

  findCount(logs: ScoutLogExtended[]): any {
    logs.forEach((log) => {
      log.count = 0;
      log.sensors.forEach((sensor) => {
        if (sensor.testSensor === false) {
          log.count++;
        }
      });
    });
    return logs;
  }

  clickSensors(log: ScoutLogExtended) {
    log.sensorsExpanded = !log.sensorsExpanded;
  }

  clickTestResult(log: ScoutLogExtended) {
    log.testsExpanded = !log.testsExpanded;
  }
}
